export const experienceConfig = [
  {
    name: "Frontend Simplified",
    title: "Frontend Support Engineer",
    description: [
      "Provided real-time debugging and technical support to 700+ students, leveraging advanced problem-solving skills to identify and resolve complex coding issues during live chat and online sessions,",
      "Facilitated a collaborative learning environment by delivering tailored guidance, enhancing students understanding of programming concepts, and driving their progression in software development,",
      "Designed and implemented resource materials and coding examples to simplify complex concepts, boosting student engagement and comprehension.",
    ],
    date: "Sep 2024 - Current",
  },
  {
    name: "Intel Corporation Green Badge / Sii Poland",
    title: "Validation Engineer Internship",
    description: [
      "Conducted advanced manual and automated testing using C#, ensuring adherence to rigorous quality standards for Intel’s cutting-edge solutions,",
      "Systematically analyzed and documented test outcomes, providing actionable insights to enhance team workflows and streamline operational efficiency,",
      "Engaged in a structured Python-focused mentoring program, expanding technical competencies and fostering innovation-driven problem-solving.",
    ],
    date: "Sep 2023 - Mar 2024",
  },
  {
    name: "Frontend Simplified",
    title: "Frontend Developer Internship",
    description: [
      "Transformed a completely static HTML, CSS, JavaScript and React single page application into an interactive user interface using animations, transitions and carousels,",
      "Processed API requests with Axios to dynamically represent data from a cloud server and represented it through skeleton loading states, pagination and dynamic routing,",
      "Utilized Git version control and the GitHub interface to work and thrive in a virtual and collaborative team environment.",
    ],
    date: "Apr 2023 - Sep 2023",
  },
  {
    name: "English Me",
    title: "Private Math Tutor",
    description: [
      "Developed bespoke instructional strategies to elevate mathematical comprehension and engagement,",
      "Leveraged performance analytics to deliver targeted feedback and ensure sustained academic progression.",
    ],
    date: "Jan 2023 - Current",
  },
];
