import { useEffect, useRef } from "react";

function CustomCursor() {
  const primaryCursorRef = useRef(null);

  useEffect(() => {
    // Define the event handler
    const handleMouseMove = (e) => {
      if (primaryCursorRef.current) {
        // Ensure the ref is attached
        const { clientX, clientY } = e;
        const cursorWidth = primaryCursorRef.current.clientWidth;
        const cursorHeight = primaryCursorRef.current.clientHeight;

        // Calculate the position to center the cursor
        const mouseX = clientX - cursorWidth / 2;
        const mouseY = clientY - cursorHeight / 2;

        // Apply the transformation
        primaryCursorRef.current.style.transform = `translate3d(${mouseX}px, ${mouseY}px, 0)`;
      }
    };

    // Attach the event listener without delay
    document.addEventListener("mousemove", handleMouseMove);

    // Cleanup function to remove the event listener on unmount
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div className="main-cursor customcursor" ref={primaryCursorRef}></div>
  );
}

export default CustomCursor;
