export const technicalSkillsConfig = [
  "JavaScript",
  "TypeScript",
  "React",
  "CSS",
  "HTML",
  "Sass",
  "Next.js",
  "Node.js",
  "Express.js",
  "MongoDB",
  "Python",
  "C++",
  "C",
  "MySQL",
];
